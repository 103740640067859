import './photoalbum.scss';

import * as dompack from 'dompack';

require('./lightgallery/js/lightgallery.min.js');
require('./lightgallery/js/lg-video.min.js');
// require('./lightgallery/js/lg-thumbnail.min.js');
require('./lightgallery/js/lg-fullscreen.min.js');
require('./lightgallery/js/lg-zoom.min.js');

dompack.onDomReady(() => {
  let options = {
    youtubePlayerParams: { modestbranding: 1 },
    thumbnail: true,
    mode: 'lg-soft-zoom',
    videoMaxWidth: '80vw',
  };

  for (const gallery of dompack.qSA('.photoalbum__items')) {
    lightGallery(gallery, options);
  }
});
