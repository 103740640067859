import './paneltabs.scss';
import $ from 'jquery';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  	$('.emb-paneltabs__item__heading--expand').on('click', function(){
	  	let item = $(this.closest('.emb-paneltabs__item'));

	  	/* Code below only allows one tab to be opend at a time */
	  	let parent = $(item.parent('.emb-paneltabs--container'));
	  	let items = $(parent.find('.emb-paneltabs__item'));

	  	items.each(function(){
	  		let object = $(this);
	  		let content = $(object.find('.emb-paneltabs__item__content'));
	  		if(content.is(":visible")){
	  			content.slideUp();
	  			changeIcon(object, 'fa-minus', 'fa-plus');
	  		}
	  	});
	  	/* Code above only allows one tab to be opend at a time */

	  	let content = $(item.find('.emb-paneltabs__item__content'));
	  	if(content.is(":hidden")){
	  		content.slideDown();
	  		changeIcon(item, 'fa-plus', 'fa-minus');
			}else{
				content.slideUp();
				changeIcon(item, 'fa-minus', 'fa-plus');
			}

			function changeIcon(target, oldIconClass, newIconClass){
				let icon = $(target.find('.' + oldIconClass));
  			if(icon != undefined){
			  	icon.removeClass(oldIconClass);
			  	icon.addClass(newIconClass);
			  }
			}
  	});
});
