require('./organizations.scss');

import * as dompack from 'dompack';
import $ from '@mod-mijnsiteonline/vendor/jquery-1.11.3/jquery-1.11.3.min.js';

// import URLBuilder from 'dompack/extra/urlbuilder';

import { setupDefaultPageNavigation } from '@mod-mijnsiteonline/nodejs/utilities';

let numPerPage = 40;
let organizationsList = null;

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-organizations'))
    return;

  let $organizationsList = $('#organizationslist');
  let $pageNav = $('#organizationslist .pagenav');
  let addPageNav = $organizationsList.data('pagenav') === true;

  organizationsList = setupDefaultPageNavigation({
    containerId: 'organizationslist',
    delayedLoadImages: true,
    classImage: 'organizations__logo',
    numPerPage: addPageNav ? numPerPage : 99999999,
    valueNames: ['organizations__title'],
  });

  let url = new URL(location.href);
  let searchParam = url.searchParams.get('q');
  if (searchParam) {
    organizationsList.search(searchParam);
    dompack.qS('.organizations__searchinput').value = searchParam;
  }

  if (dompack.qSA('.pagenav__pages li').length == 1)
    dompack.qS('.pagenav').style.display = 'none';

  for (const link of dompack.qSA('.organizations__branche')) {
    link.addEventListener('click', evt => {
      evt.preventDefault();
      selectBranche(evt.currentTarget);
    });
  }

  $('.organizations__searchinput').on('input', function(){
    $('.js-reset-input').css('display', $(this).val().length >= 1 ? 'block' : 'none');
  });

  $('.js-reset-input').on('click', function (e) {
    e.preventDefault();
    $('.organizations__searchinput').val('').focus();
    $('.js-reset-input').css('display', 'none');
  })
});

function selectBranche(selectedBrancheNode) {
  // remove selection from all branche links
  for (const branche of dompack.qSA('.organizations__branche')) {
    branche.classList.remove('organizations__branche--selected');
  }

  // select the current one
  selectedBrancheNode.classList.add('organizations__branche--selected');

  // filter on the selected branche
  let brancheId = parseInt(selectedBrancheNode.getAttribute('data-filter-branche'), 10);

  organizationsList.filter(function(item) {
    if (brancheId === -1) // filtering on all branches, eg enable this one
      return true;

    let branches = item.elm.getAttribute('data-branches'); // '1 2 3 4'

    let organizationBrancheIds = branches.split(' ').map(function(item) { // results in [1,2,3,4]
      return parseInt(item, 10);
    });

    // return TRUE if filtered branche id in organization branche ids
    return $.inArray(brancheId, organizationBrancheIds) > -1;
  });

  dompack.toggleClasses(dompack.qS('.pagenav'), { 'pagenav--hidden': organizationsList.matchingItems.length <= numPerPage });
}
