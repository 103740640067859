import './searchexpand.scss';

import * as dompack from 'dompack';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.searchexpand__searchicon').click(toggleSearch);
  $('.searchexpand__searchclose').click(closeSearch);
});

function toggleSearch(evt) {
  evt.preventDefault();

  if ($('html').hasClass('searchexpand-search-active')) { // it's active, submit form
    dompack.qS('#searchexpand-search').submit();
    return;
  } else {
    $('.searchexpand__searchinput').focus();
  }

  $('html').toggleClass('searchexpand-search-active');
}

function closeSearch(evt) {
  evt.preventDefault();
  $('html').removeClass('searchexpand-search-active');
}
