/* global $ */
require('./intranet.scss');

// const whBase = require('@mod-system/js/compat/base');
import * as whintegration from '@mod-system/js/wh/integration';

import WRDAuthenticationProvider from "@mod-wrd/js/auth";

let rpc = require('./intranet.rpc.json');

import $ from '@mod-mijnsiteonline/vendor/jquery-1.11.3/jquery-1.11.3.min.js';

// dompack
import * as dompack from 'dompack';

let breadCrumbItems = [];
let flatFolderList = [];

document.addEventListener("wh:wrdauth-loginfailed", e =>
{
  if (!document.documentElement.classList.contains('page-intranet'))
    return;

  e.preventDefault();
  alert('De ingevulde inloggegevens zijn helaas niet correct. Probeert u het a.u.b. nog eens.');
});

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-intranet'))
    return;

  let filemanager = $('.filemanager');
  if (filemanager.length) {
    runFileManager(filemanager);
    return;
  }

  // login screen
  if (dompack.debugflags.dev) {
    $('input[name="login"]').val('wouter@webwerf.nl');
    $('input[name="password"]').val('a');
  }
});

function runFileManager(filemanager) {
  let pageHash = decodeURIComponent(location.hash).slice(1).split('=');
  if (pageHash.length > 1 && pageHash[0] === 'search')
    $('#intranetsearch').val(pageHash[1]);

  let breadcrumbs = $('.breadcrumbs'),
    fileList = filemanager.find('.data');

  rpc.getIntranetFSTree(whintegration.config.obj.startfolderid, whintegration.config.obj.currentfileid)
     .then((data) => {

          if (!data) {
            filemanager.find('.nothingfound').show();
            filemanager.find('.search').hide();
            return;
          }

          filemanager.find('.search').show();

          flatFolderList = data.folders;

          data = data.menu;
          let response = [data],
            currentPath = '',
            breadcrumbsUrls = [];

          let folders = [],
            files = [];

          // This event listener monitors changes on the URL. We use it to
          // capture back/forward navigation in the browser.

          $(window).on('hashchange', function(){

            goto(window.location.hash);

            // We are triggering the event. This will execute
            // this function on page load, so that we show the correct folder:

          }).trigger('hashchange');


    // Hiding and showing the search box

    // Listening for keyboard input on the search field.
    // We are using the "input" event which detects cut and paste
    // in addition to keyboard input.

    filemanager.find('input').on('input', function(e){

      folders = [];
      files = [];

      let value = this.value.trim();

      if(value.length) {

        filemanager.addClass('searching');

        // Update the hash on every key stroke
        window.location.hash = 'search=' + value.trim();

      }

      else {

        filemanager.removeClass('searching');
        window.location.hash = encodeURIComponent(currentPath);

      }

    }).on('keyup', function(e){
    }).focusout(function(e){

      // Cancel the search

      let search = $(this);

      if(!search.val().trim().length) {

        window.location.hash = encodeURIComponent(currentPath);
      }

    });

          // Clicking on folders

          fileList.on('click', 'li.folders', function(e){
            e.preventDefault();

            let nextDir = $(this).find('a.folders').attr('href');

            if(filemanager.hasClass('searching')) {

              // Building the breadcrumbs

              breadcrumbsUrls = generateBreadcrumbs(nextDir);

              filemanager.removeClass('searching');
              filemanager.find('span').show();
            }
            else {
              breadcrumbsUrls.push(nextDir);
            }

            window.location.hash = encodeURIComponent(nextDir);
            currentPath = nextDir;
          });


          // Clicking on breadcrumbs

          breadcrumbs.on('click', 'a', function(e){
            e.preventDefault();

            let index = breadcrumbs.find('a').index($(this)),
              nextDir = breadcrumbsUrls[index];

            breadcrumbsUrls.length = Number(index);

            window.location.hash = encodeURIComponent(nextDir);

          });


          // Navigates to the given hash (path)

          function goto(hash) {

            hash = decodeURIComponent(hash).slice(1).split('=');

            if (hash.length) {
              let rendered = '';

              // if hash has search in it
              if (hash[0] === 'search') {

                filemanager.addClass('searching');
                rendered = searchData(response, hash[1].toLowerCase());

                if (rendered.length) {
                  currentPath = hash[0];
                  render(rendered);
                }
                else {
                    render(rendered);
                }

              }

              // if hash is some path
              else if (hash[0].trim().length) {
                rendered = searchByPath(hash[0]);

                currentPath = hash[0];
                breadcrumbsUrls = generateBreadcrumbs(hash[0]);
                render(rendered);
              }

              // if there is no hash
              else {
                currentPath = data.path;
                breadcrumbsUrls.push(data.path);
                render(searchByPath(data.path));
              }
            }
          }

          // Splits a file path and turns it into clickable breadcrumbs

          function generateBreadcrumbs(nextDir){
            let path = nextDir.split('/').slice(0);
            for(let i=1;i<path.length;i++){
              path[i] = path[i-1]+ '/' +path[i];
            }
            return path;
          }


  // Locates a file by path

    function searchByPath(dir) {
      let path = dir.split('/'),
        demo = response,
        flag = 0;

      for(let i=0;i<path.length;i++){
        for(let j=0;j<demo.length;j++){
          if(demo[j].name === path[i]){
            flag = 1;
            demo = demo[j].items;
            break;
          }
        }
      }

      demo = flag ? demo : [];
      return demo;
    }


    // Recursively search through the file tree

    function searchData(data, searchTerms) {

      data.forEach(function(d){
        if(d.type === 'folder') {

          searchData(d.items,searchTerms);

          if(d.name.toLowerCase().match(searchTerms)) {
            folders.push(d);
          }
        }
        else if(d.type === 'file') {
          if(d.name.toLowerCase().match(searchTerms)) {
            files.push(d);
          }
        }
      });

      return {folders: [], files: files};
    }


          // Render the HTML for the file manager

          function render(data) {

            let scannedFolders = [],
              scannedFiles = [];

            if(Array.isArray(data)) {

              data.forEach(function (d) {

                if (d.type === 'folder') {
                  scannedFolders.push(d);
                }
                else if (d.type === 'file') {
                  scannedFiles.push(d);
                }

              });

            }
            else if(typeof data === 'object') {

              scannedFolders = data.folders;
              scannedFiles = data.files;

            }


            // Empty the old result and make the new one

            fileList.empty().hide();

            if(!scannedFolders.length && !scannedFiles.length) {
              filemanager.find('.nothingfound').show();
            }
            else {
              filemanager.find('.nothingfound').hide();
            }

            if(scannedFolders.length) {

              scannedFolders.forEach(function(f) {

                let itemsLength = f.items.length,
                  name = escapeHTML(f.name),
                  icon = '<span class="icon folder"></span>';

                if(itemsLength > 0) {
                  icon = '<span class="icon folder full"></span>';
                }

                if(itemsLength == 1) {
                  itemsLength += ' onderdeel';
                }
                else if(itemsLength > 1) {
                  itemsLength += ' onderdelen';
                }
                else {
                  itemsLength = 'Lege map';
                }

                let folder = $('<li class="folders"><a href="'+ f.path +'" title="'+ f.title +'" class="folders">'+icon+'<span class="name">' + f.title + '</span> <span class="details">' + itemsLength + '</span></a></li>');
                folder.appendTo(fileList);
              });

            }

            if(scannedFiles.length) {

              scannedFiles.forEach(function(f) {

                let fileSize = bytesToSize(f.size),
                  name = escapeHTML(f.name),
                  fileType = name.split('.'),
                  icon = '<span class="icon file"></span>';

                fileType = fileType[fileType.length-1];

                icon = '<span class="icon file f-'+fileType+'">.'+fileType+'</span>';

                let file = $('<li class="files">'
                           + '  <a href="'+ f.path+'" title="' + f.path + '" class="files">'
                           +      icon
                           + '    <span class="name">' + f.title + '</span>'
                           + '    <span class="details">' + fileSize + '</span>'
                           + '  </a>'
                           + '</li>');

                file.appendTo(fileList);
              });

            }

            // Generate the breadcrumbs

            let url = '';

            if(filemanager.hasClass('searching')){

              url = '<span>Zoekresultaten:</span>';
              fileList.removeClass('animated');

            }
            else {

              fileList.addClass('animated');

              breadcrumbsUrls.forEach(function (u, i) {

                let name = u.split('/');

                const folderObj = findFolderByPath(u);
                let folderTitle = folderObj && folderObj.title ? folderObj.title : name[name.length-1];

                if (i !== breadcrumbsUrls.length - 1) {
                  url += '<a href="'+u+'"><span class="foldername">' + folderTitle + '</span></a> <span class="arrow">→</span> ';
                }
                else {
                  url += '<span class="foldername">' + folderTitle + '</span>';
                }

              });

            }

            breadcrumbs.text('').append(url);


            // Show the generated elements
            fileList.animate({'display':'inline-flex'});
            fileList.css('display','flex');
          }


          // This function escapes special html characters in names

          function escapeHTML(text) {
            return text.replace(/\&/g,'&amp;').replace(/\</g,'&lt;').replace(/\>/g,'&gt;');
          }


          // Convert file sizes from bytes to human readable units

          function bytesToSize(bytes) {
            let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0B';
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
          }

          function findFolderByPath(findPath) {
            let result = {};

            flatFolderList.forEach(function(folderFromList) {
              if (folderFromList.path === findPath) {
                result = folderFromList;
                return true;
              }
            });

            return result;
          }

     })
     .catch(onFormException);
};

function onFormException(result = {}) {
  console.error(result);
}
