import './modal.scss';

import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';

import $ from 'jquery';

export async function runModal($contents) {
  $contents = $contents.clone().addClass('modal');

  // create dialog and add contents to it
  let dialog = dialogapi.createDialog();
  $(dialog.contentnode).append($contents);

  let button = dompack.create('button', { className: 'modal__closebutton'
                                        , textContent: 'x'
                                        , type: 'button'
                                        , on: { click: event => dialog.resolve()
                                              }
                                        });
  dialog.contentnode.appendChild(button);

  // show dialog
  await dialog.runModal();
}
