require('./news.scss');

import * as dompack from "dompack";
import $ from '@mod-mijnsiteonline/vendor/jquery-1.11.3/jquery-1.11.3.min.js';


dompack.onDomReady(() => {

  if (!document.documentElement.classList.contains('page-news'))
    return;

  // Category filter code
  for (const link of dompack.qSA('.newsitems__category')) {
    link.addEventListener('click', evt => {
      evt.preventDefault();
      selectCategory(evt.currentTarget);
    });
  }

  $('.js-reset-input').on('click', function (e) {
    e.preventDefault();
    $('.organizations__searchinput').val('').focus();
    $('.js-reset-input').css('display', 'none');
  });
});

function selectCategory(selectedCategory) {
  // remove selection from all category links
  for (const category of dompack.qSA('.newsitems__category')) {
    category.classList.remove('newsitems__category--selected');
  }

  // select the current one
  selectedCategory.classList.add('newsitems__category--selected');

  // filter on the selected category
  let categoryId = parseInt(selectedCategory.getAttribute('data-filter-category'), 10);

  $("ul.list > li").filter(function(item) {

    $($("ul.list > li")[item]).css("display", 'none');

    if (categoryId === -1) // filtering on all categories, eg enable this one
      return true;

    let categories = $("ul.list > li")[item].getAttribute('data-categories'); // '1 2 3 4'

    let newsitemCategoryIds = categories.split(' ').map(function(item) { // results in [1,2,3,4]
      return parseInt(item, 10);
    });
    // return TRUE if filtered category id in organization category ids
    return $.inArray(categoryId, newsitemCategoryIds) > -1;
  }).css("display", "flex");
}

