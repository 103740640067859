import './footercmb.scss';

import * as dompack from 'dompack';
// import whBase from '@mod-system/js/compat/base';
const rpc = require('./footercmb.rpc.json');

import $ from '@mod-mijnsiteonline/vendor/jquery-1.11.3/jquery-1.11.3.min.js';

dompack.onDomReady(() => {
  if ($('.footercmb').length == 0)
    return;

  // debug fields
  if (dompack.debugflags.dev === true) {
    $('#footercmb-input-name').val('Pietje Puk');
    $('#footercmb-input-phone').val('06-12345678');
  }

  // submit handler
  $('.footercmb__form').submit(function(evt) {
    evt.preventDefault();

    let siteRootObjectId = parseInt($(this).data('siterootobjectid'));

    const formData = { name: $('#footercmb-input-name').val(),
                       phone: $('#footercmb-input-phone').val(),
                     };

    rpc.submitFooterCMBForm(formData, siteRootObjectId)
       .then(onFormSuccess)
       .catch(onFormException);
  });
});

function onFormSuccess(result = {}) {
  $('.footercmb .wh-form--submitcontext').addClass('wh-form--submitted');
}

function onFormException(result = {}) {
  alert('Er ging iets mis bij het verzenden van het formulier. Neemt u a.u.b. contact op met ons via de contactpagin als het probleem zich blijft voordoen.');
  console.error(result);
}
