import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
forms.setup({ validate: true });
import UploadField from '@mod-publisher/js/forms/fields/upload';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
import RTDField from '@mod-publisher/js/forms/fields/rtd';

import "./forms.scss";

// import $ from "jquery"; // parsleyjs needs jquery
// import "parsleyjs";
// import ParsleyForm from '@mod-publisher/js/forms/parsley';
import "./forms.lang.json";

// dompack.register(".wh-form", form => new ParsleyForm($, form));
dompack.register(".wh-form__upload", node => new UploadField(node));
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
dompack.register(".wh-form__rtd", node => new RTDField(node, rtdopts));

// import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
// googleRecaptcha.setupGoogleRecaptcha();
