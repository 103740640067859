import './slideshow.scss';

import * as dompack from 'dompack';

import $ from 'jquery';
import Swiper from '@mod-mijnsiteonline/vendor/swiper-3.4.2/swiper.min.js';
import '@mod-mijnsiteonline/vendor/swiper-3.4.2/swiper.min.css';

dompack.onDomReady(() => {
  $('.emb-slideshow').each(function() {
    let item = $(this);
    let numSlides = item.find('.emb-slideshow__col').length - 1;

    //Slider for images of the slideshow
    let swiper = new Swiper (item, {
      autoplay: 4000,
      loop: numSlides > 0,
      allowSwipeToPrev: numSlides > 0,
      allowSwipeToNext: numSlides > 0,
      nextButton: '.emb-slideshow--slide-right',
      prevButton: '.emb-slideshow--slide-left',
    });

    if (numSlides <= 0) {
      $(item.find('.emb-slideshow--slide-right')).css("display", "none");
      $(item.find('.emb-slideshow--slide-left')).css("display", "none");
    }
  });
});
