import './widgets.scss';

import * as dompack from "dompack";

dompack.onDomReady(() => {
  const isWidgetPreview = document.documentElement.classList.contains('wh-widgetpreview');
  if (isWidgetPreview) {
    document.querySelector('.widgets').classList.add('widgets--preview');
  }
});
