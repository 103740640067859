import './searchmodal.scss';

import * as dompack from 'dompack';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('#searchmodal').addClass('initialized');

  let $input = $('#searchmodal-search-input');

  $('.js-trigger-search-modal').click((evt) => {
    evt.preventDefault();
    document.documentElement.classList.add('search-active');
    $input.focus();
  });

  $('#searchmodal-close').click((evt) => {
    evt.preventDefault();
    document.documentElement.classList.remove('search-active');
    $input.blur();
  });
});
