// import WhBase from '@mod-system/js/compat/base';
import * as whintegration from '@mod-system/js/wh/integration';


import '@mod-system/js/wh/integration'; //activate WebHare features
import * as cookie from 'dompack/extra/cookie';
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
import * as dialog from 'dompack/components/dialog';
import * as dialogapi from 'dompack/api/dialog';

import './mso.scss';

import './forms';
import * as utilities from './utilities';
import $ from 'jquery';

import '@mod-mijnsiteonline/webdesigns/shared/embeddedobjects/embeddedobjects';
import "@mod-mso/pages/events/events";

// components
import '../components/abovefooter/abovefooter';
import '../components/contactheader/contactheader';
import '../components/footer/footer.scss';
import '../components/mso-filepath/mso-filepath.scss';
import '../components/searchexpand/searchexpand';
import '../components/searchmodal/searchmodal';
import '@mod-mijnsiteonline/nodejs/agenda/agenda';
import '@mod-mijnsiteonline/nodejs/intranet/intranet';
import '@mod-mijnsiteonline/nodejs/news/news';
import '@mod-mijnsiteonline/nodejs/organizations/organizations';
import '@mod-mijnsiteonline/nodejs/team/team';
import '@mod-mijnsiteonline/nodejs/widgets/widgets';

import '@mod-mijnsiteonline/components/photoalbum/photoalbum';
import '@mod-mijnsiteonline/components/slideshow/slideshow';
import '@mod-mijnsiteonline/components/slider/slider';

import * as dompack from 'dompack';

openLinksInNewWindow({ extensions: ['pdf'] });
googleRecaptcha.setupGoogleRecaptcha();
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

dompack.onDomReady(() => {
  if (document.querySelector('.wh-errorinfo'))
    document.documentElement.classList.add('wh-error');

  // cookie.write(WhBase.config.site.cookiewallname, `["analytics","marketing"]`, { duration: 365 } );

  if (shouldLoadVideos()) {
    loadIFrames();
  } else {

    let classNames = [".wh-video",".organizations__googlemap"];

    setupCookiewall(classNames);
  }

  for (const dateInput of dompack.qSA('input[type="date"]')) {
    if (dateInput.classList.contains('js-no-date-picker'))
      continue;

    if($(dateInput).attr('placeholder') == undefined){
      dateInput.setAttribute('placeholder', 'Selecteer of voer een datum in (d-m-j)');
    }

    //FIX ME: fix for Zenna hidden forms
    if($('.hidden').hasClass("nl"))
      $('.wh-form__button--submit .wh-form__buttonlabel').text("Versturen");
    else if($('.hidden').hasClass("de"))
      $('.wh-form__button--submit .wh-form__buttonlabel').text("Senden");

    let fp = flatpickr(dateInput, {
      locale: "nl",
      altInput: true,
      allowInput: true,
      altFormat: "d-m-Y",
      dateFormat: "Y-m-d",
      weekNumbers: true,
      onChange: function(selDates, dateStr) {
        this._selDateStr = dateStr;
      },
      onClose: function(selDates, dateStr, instance){
        if (this.config.allowInput && this._input.value && this._input.value !== this._selDateStr) {
          this.setDate(this.altInput.value, false, this.config.altFormat);
        }
      },
    });
  }

  for (const dateInput of dompack.qSA('input.flatpickr-input.input')) {
    dateInput.setAttribute('maxlength', 10);
  }

  // Fix dependency for flatpicker
  $('form').on("change", function(){
    $('.wh-form__fieldgroup--date').each(function(index, item){
      let $item = $(item);
      let checkon = $item.find("input")[0];
      let datepicker = $item.find("input")[1];

      $(datepicker).prop("disabled", $(checkon).prop("disabled"));
    });
  });
});

function shouldLoadVideos() {
  if (whintegration.config.site.applycookiewall === false || !whintegration.config.site.cookiewallname)
    return true;

  let cookieVal = cookie.read(whintegration.config.site.cookiewallname);
  return whintegration.config.site.applycookiewall === true && cookieVal && cookieVal.includes('marketing');
}

window.refreshOnCookieWallChange = () => {
  if (shouldLoadVideos())
    loadIFrames();
};

function loadIFrames() {

  // Load iFrames (Youtube / Google Maps)
  require('@mod-publisher/js/richcontent/all');

  for (const gmap of dompack.qSA('.organizations__googlemap')) {

      let iFrameLink = gmap.getAttribute("data-iframe");

      let iFrameHTML =
        `<iframe src="${iFrameLink}" width="100%" height="480"></iframe>`;

      gmap.insertAdjacentHTML("beforeend", iFrameHTML);
    }
}

function setupCookiewall(classNames) {
  for (const className of classNames) {

    for(const cookiewallObj of dompack.qSA(className)){
      cookiewallObj.classList.add(`iframe--cookiewalled`);

      let addHTML =
        `<div class="cookiewallcontainer">
           <p class="cookiewalltext">
             Om deze embedded content (Video's, Google Maps) te zien, moet je marketing cookies accepteren.
           </p>
           <div class="cookiewallbutton">
             <a class="button js-open-cookie-prefs">Pas je cookievoorkeuren aan</a>
           </div>
         </div>`;

      cookiewallObj.insertAdjacentHTML('beforeend', addHTML);
    }
  }
}
