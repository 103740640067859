// Auto-generated RPC interface from /opt/whdata/installedmodules/mijnsiteonline.20241029T143047.669Z/components/abovefooter/footercmb/footercmb.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("mijnsiteonline:rpc");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/mijnsiteonline.20241029T143047.669Z/include/rpc.whlib'

exports.submitFooterCMBForm = exports.SubmitFooterCMBForm = /*RECORD*/function(/*RECORD*/ formfields, /*INTEGER*/ siterootobjectid)
{
return request.invoke.apply(request,["SubmitFooterCMBForm"].concat(Array.prototype.slice.call(arguments)));
}
