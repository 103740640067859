import './verticaltabs.scss';

import * as dompack from 'dompack';

import $ from 'jquery';
import Swiper, { EffectFade } from 'swiper';
import 'swiper/css';

import * as modal from '@mod-mijnsiteonline/components/modal/';

dompack.onDomReady(() => {
  activateTabs();

  //When window resolution is changed (disable for now)
  // $(window).resize(function() {
  //   activateTabs();
  // });

  function activateTabs() {
    $('.emb-verticaltabs').each(function() {
      let $embContainer = $(this);

      let isMobile = $(window).width() <= 768;

      if (isMobile) {
        $embContainer.find('a[data-modal]').click(function(evt) {
          evt.preventDefault();

          let modalId = $(this).data('modal-id');

          // get parent container
          let $container = $(this).closest('.emb-verticaltabs');

          // find the modal contents
          let $contents = $container.find(`.modal[data-modal-id="${modalId}"]`);

          modal.runModal($contents);
        });
      } else {
        // Small trick to add min height to the swiper items so the bullets can render all
        var minHeight = $embContainer.find('.emb-verticaltabs__bullets').height();
        $embContainer.find('.swiper-wrapper').css('min-height', minHeight);

        let $swiperContainer = $embContainer.find('.swiper-container');

        // setup photos carousel
        var carouselSwiper = new Swiper($swiperContainer.get(0), {
          modules: [ EffectFade ],
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
        });

        var $bullets = $embContainer.find('.emb-verticaltabs__bullet');
        carouselSwiper.setTranslate(0);

        $bullets.each(function(idx, el) {
          $(this).unbind();
          $(this).click(function(evt) {
            evt.preventDefault();
            $bullets.removeClass('selected').eq(idx).addClass('selected');
            carouselSwiper.setProgress(idx, 500);
          });
        });
      }
    });
  }
});
