import './contactheader.scss';

import * as dompack from 'dompack';
import $ from '@mod-mijnsiteonline/vendor/jquery-1.11.3/jquery-1.11.3.min.js';

dompack.onDomReady(() => {
  if(document.getElementsByClassName('contactheader')[0] !== undefined) {
    createHeaderPadding();

    $(window).resize(function(){
      createHeaderPadding();
    });
  }
  
  function createHeaderPadding() {
    $('#header').css('top', $('.contactheader').height());
    $('#my-page').css('padding-top', $('.contactheader').height() + ($('#header').height()));
  }

  // $(window).scroll(function(){
  //   var scrollHeight = $(window).scrollTop();
  //   var chHeight = $('.contactheader').height();
  //   var posHeight = chHeight - scrollHeight;
  //   if(scrollHeight > chHeight){
  //     $('#header').css('top', 0);
  //   } else {
  //     $('#header').css('top', posHeight);
  //   }
  // });
});
